import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ErrorCategory } from "@/components/Pages/Transactions/Transactions.types";
import getCountryFlagPath from "@/components/Utils/CountryFlagPath";
import { useCountries } from "@/hooks/useCountries";

export const useUserStatusOptions = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        defaultMessage: "Active",
        id: "zLI2iw",
        description: "User status options > Active",
      }),
      value: "active",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Inactive",
        id: "fjwgGk",
        description: "User status options > Inactive",
      }),
      value: "inactive",
    },
  ];
};

interface CountryOptionsProps {
  flagRatio?: "1x1" | "3x4";
  excludeFlag?: boolean;
}

export const useCountryOptions = (props?: CountryOptionsProps) => {
  const { isLoading, countries } = useCountries();
  const { flagRatio, excludeFlag } = props || {};

  return {
    isLoadingCountryOptions: isLoading,
    countryOptions: Object.values(countries).map(({ id, name }) => ({
      value: id,
      label: name,
      ...(excludeFlag ? {} : { flag: getCountryFlagPath(id, flagRatio) }),
    })),
  };
};

export type DateRange =
  | "today"
  | "last_7_days"
  | "last_14_days"
  | "last_30_days"
  | "last_3_months"
  | "last_6_months"
  | "last_12_months"
  | "custom";

export interface DateRangeFilterOptionsProps {
  exclude?: DateRange[];
}

export const useDateRangeFilterOptions = (options?: DateRangeFilterOptionsProps) => {
  const intl = useIntl();

  const dateRangeOptions: { value: DateRange; label: ReactNode }[] = [
    {
      value: "today",
      label: intl.formatMessage({
        defaultMessage: "Today",
        id: "OQ1vc/",
        description: "Date range filter options > Today",
      }),
    },
    {
      value: "last_7_days",
      label: intl.formatMessage({
        defaultMessage: "Last 7 days",
        id: "aLuzlm",
        description: "Date range filter options > Last 7 days",
      }),
    },
    {
      value: "last_14_days",
      label: intl.formatMessage({
        defaultMessage: "Last 14 days",
        id: "HLrOwi",
        description: "Date range filter options > Last 14 days",
      }),
    },
    {
      value: "last_30_days",
      label: intl.formatMessage({
        defaultMessage: "Last 30 days",
        id: "nJL4a3",
        description: "Date range filter options > Last 30 days",
      }),
    },
    {
      value: "last_3_months",
      label: intl.formatMessage({
        defaultMessage: "Last 3 months",
        id: "fTorg3",
        description: "Date range filter options > Last 3 months",
      }),
    },
    {
      value: "last_6_months",
      label: intl.formatMessage({
        defaultMessage: "Last 6 months",
        id: "3HuI5H",
        description: "Date range filter options > Last 6 months",
      }),
    },
    {
      value: "last_12_months",
      label: intl.formatMessage({
        defaultMessage: "Last 12 months",
        id: "sOuhm4",
        description: "Date range filter options > Last 12 months",
      }),
    },
    {
      value: "custom",
      label: intl.formatMessage({
        defaultMessage: "Specific dates",
        id: "VjY2qR",
        description: "Date range filter options > Specific dates",
      }),
    },
  ];

  if (!options?.exclude) return dateRangeOptions;

  return dateRangeOptions.filter((option) => !options?.exclude?.includes(option.value));
};

export const useTransactionDocumentTypes = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        defaultMessage: "Debit",
        id: "CcDN2l",
        description: "Transaction document type",
      }),
      value: "debit",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Credit",
        id: "zey7K3",
        description: "Transaction document type",
      }),
      value: "credit",
    },
  ];
};

export const CUSTOM_FONT_OPTIONS = [
  {
    label: "Roboto",
    value: "Roboto",
    fontFaces: [
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Medium.ttf)",
        options: { weight: "600" }, // There is no Roboto Semibold, so we'll use the Medium weight for this
      },
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Open Sans",
    value: "Open Sans",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Source Serif Pro",
    value: "Source Serif Pro",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Source Sans Pro",
    value: "Source Sans Pro",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Oswald",
    value: "Oswald",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Lora",
    value: "Lora",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Noto Sans",
    value: "Noto Sans",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/NotoSans-Regular.ttf)",
        options: { weight: "400" },
      },
      // There is no NotoSans Semibold
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/NotoSans-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
];

export const ERROR_TRANSACTION_CATEGORIES = [
  {
    label: (
      <FormattedMessage
        description="Error Transaction Category"
        defaultMessage="Tax Authority Error"
        id="SYR25Z"
      />
    ),
    value: ErrorCategory.TaxAuthority,
  },
  {
    label: (
      <FormattedMessage
        description="Error Transaction Category"
        defaultMessage="Validation Error"
        id="5EJ5Zr"
      />
    ),
    value: ErrorCategory.Validation,
  },
  {
    label: (
      <FormattedMessage
        description="Error Transaction Category"
        defaultMessage="Other"
        id="0Sfdww"
      />
    ),
    value: ErrorCategory.General,
  },
];

export const ERROR_TRANSACTION_STATUSES = [
  {
    label: (
      <FormattedMessage
        description="Error Transaction Status"
        defaultMessage="Resolved"
        id="fYGp2B"
      />
    ),
    value: "resolved",
  },
  {
    label: (
      <FormattedMessage
        description="Error Transaction Status"
        defaultMessage="Unresolved"
        id="/FbWd5"
      />
    ),
    value: "unresolved",
  },
];
