import classNames from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

import { H6 } from "@/components/Typography/H";
import { convertDataAttributes, DataAttributes } from "@/components/Utils/DataAttributes";
import getIcon, { IconType } from "@/hooks/getIcon";

type SizeVariant = "EXTRA_SMALL" | "SMALL" | "REGULAR" | "LARGE";
type TextSizeVariant = "XS" | "SM" | "BASE" | "LG";
type ColorVariant = "DARK_BLUE" | "PRIMARY_BLUE_900";
export type StateVariant = "DEFAULT" | "ERROR";
export type IconVariant = "SUCCESS" | "ALERT" | "GRAY";

const STATE_MAPS: Record<StateVariant, string> = {
  DEFAULT: "border border-blueGray200 focus:border-infoLight",
  ERROR: "border bg-dangerBg border-danger focus:border-dangerLight ring-dangerLight",
};

const SIZE_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "h-6",
  SMALL: "",
  REGULAR: "h-10",
  LARGE: "h-12",
};

const COLOR_MAPS: Record<ColorVariant, string> = {
  DARK_BLUE: "text-darkBlue",
  PRIMARY_BLUE_900: "text-primaryBlue900",
};

const TEXT_SIZE_MAPS: Record<TextSizeVariant, string> = {
  XS: "text-xs",
  SM: "text-sm",
  BASE: "text-base",
  LG: "text-lg",
};

const LEFT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "left-6",
  REGULAR: "left-6",
  LARGE: "left-6",
};

const RIGHT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "right-6",
  REGULAR: "right-6",
  LARGE: "right-6",
};

const ICON_VARIANT_MAPS: Record<IconVariant, string> = {
  SUCCESS: "text-success",
  ALERT: "text-danger",
  GRAY: "text-blueGray600",
};

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  className?: string;
  iconVariant?: IconVariant;
  color?: ColorVariant;
  textSize?: TextSizeVariant;
  leftIcon?: IconType | undefined;
  rightIcon?: IconType | undefined;
  state?: StateVariant;
  size?: SizeVariant;
  helper?: string;
  helperRenderer?: (helper: string) => ReactNode;
  rounded?: boolean;
  dataAttributes?: DataAttributes;
  onRightIconClick?: () => void;
  leftIconSize?: number;
  rightIconSize?: number;
  disableErrorIcon?: boolean;
  errorMessage?: string;
  fluid?: boolean;
  name?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
}

export function Input({
  rounded = true,
  size = "SMALL",
  state = "DEFAULT",
  dataAttributes = {},
  leftIcon,
  rightIcon,
  iconVariant,
  className,
  onRightIconClick,
  leftIconSize,
  rightIconSize,
  disableErrorIcon,
  errorMessage,
  fluid,
  name,
  register,
  required,
  textSize = "SM",
  color = "DARK_BLUE",
  ...props
}: InputProps) {
  const LeftIconComponent = getIcon(leftIcon);
  const dataAttr = convertDataAttributes(dataAttributes);

  function getRightIcon() {
    if (state === "ERROR" && !disableErrorIcon) return getIcon("error");
    if (rightIcon) return getIcon(rightIcon);
    return null;
  }
  const RightIconComponent = getRightIcon();

  return (
    <span className="relative w-full">
      <div className="relative w-full">
        {LeftIconComponent && (
          <LeftIconComponent
            className={classNames(
              "absolute top-1/2 transform -translate-y-1/2 left-4",
              LEFT_ICON_MAPS[size],
              iconVariant && ICON_VARIANT_MAPS[iconVariant]
            )}
            size={leftIconSize || 16}
          />
        )}

        {RightIconComponent && (
          <div
            onClick={onRightIconClick}
            className={classNames({
              "cursor-pointer": onRightIconClick,
            })}
          >
            <RightIconComponent
              className={classNames(
                "absolute top-1/2 transform -translate-y-1/2",
                RIGHT_ICON_MAPS[size],
                iconVariant && ICON_VARIANT_MAPS[iconVariant]
              )}
              size={rightIconSize || 16}
            />
          </div>
        )}
        <input
          {...(register && name ? register(name, { required }) : {})}
          className={classNames(
            "p-0 leading-8 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-1",
            STATE_MAPS[state],
            SIZE_MAPS[size],
            COLOR_MAPS[color],
            TEXT_SIZE_MAPS[textSize],
            { rounded, "pl-11": leftIcon, "pl-4": !leftIcon, "w-full": fluid },
            className
          )}
          {...dataAttr}
          {...props}
        />
      </div>
      <div
        className={classNames("min-h-5 mt-1 text-red500", {
          hidden: !errorMessage,
        })}
      >
        {errorMessage && (
          <H6 color="text-red500" weight="regular">
            {errorMessage}
          </H6>
        )}
      </div>
    </span>
  );
}
