import { ReactNode } from "react";
import { Slide, toast, ToastOptions } from "react-toastify";

import { H3 } from "@/components/Typography/H";
import { DataAttributes } from "@/components/Utils/DataAttributes";
import getIcon from "@/hooks/getIcon";

type ToastBodyProps = {
  title: ReactNode;
  description?: ReactNode;
  dataAttributes?: DataAttributes;
  Icon: () => JSX.Element | null;
};

const ToastBody = ({ title, description, dataAttributes, Icon }: ToastBodyProps) => (
  <div className="flex items-center">
    <div>
      <Icon />
    </div>
    <div className="pl-4 ml-1">
      <H3 dataAttributes={dataAttributes} className="font-medium">
        {title}
      </H3>
      <p className="text-sm">{description}</p>
    </div>
  </div>
);

interface ToastConfig {
  type?: "success" | "error" | "warning" | "info";
  description?: ReactNode;
  dataAttributes?: DataAttributes;
}

const Toast = (title: string | ReactNode, config?: ToastConfig, toastifyConfig?: ToastOptions) => {
  const CloseIcon = getIcon("close");

  const customToastifyConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    transition: Slide,
    closeButton: CloseIcon ? () => <CloseIcon className="mt-1 text-default" size={16} /> : false,
    ...toastifyConfig,
  };

  const StatusSuccessIcon = getIcon("statusSuccess");
  const StatusErrorIcon = getIcon("statusError");
  const StatusWarningIcon = getIcon("statusWarning");
  const StatusInfoIcon = getIcon("statusInfo");

  switch (config?.type) {
    case "success":
      return toast.success(
        <ToastBody
          title={title}
          description={config?.description}
          dataAttributes={config?.dataAttributes}
          Icon={() => StatusSuccessIcon && <StatusSuccessIcon size={28} />}
        />,
        customToastifyConfig
      );
    case "error":
      return toast.error(
        <ToastBody
          title={title}
          description={config?.description}
          Icon={() => StatusErrorIcon && <StatusErrorIcon size={28} />}
        />,
        customToastifyConfig
      );
    case "warning":
      return toast.warning(
        <ToastBody
          title={title}
          description={config?.description}
          Icon={() => StatusWarningIcon && <StatusWarningIcon size={28} />}
        />,
        customToastifyConfig
      );
    default:
      return toast.info(
        <ToastBody
          title={title}
          description={config?.description}
          Icon={() => StatusInfoIcon && <StatusInfoIcon size={28} />}
        />,
        customToastifyConfig
      );
  }
};

export default Toast;
