export interface TransactionsFilterProps {
  // Name of selected company, only for user view, not part of filters
  invoice_number?: string;
  date_from?: string;
  date_to?: string;
  invoice_scenario?: string;
}

export interface TableTransactionItem extends TransactionItem {
  doc_type: "debit" | "credit";
  supplier_name: string;
  currency: string;
}

// API response for /transactions endpoint
export interface TransactionItem {
  id: string;
  invoice_number: string;
  total_net_amount: number;
  total_tax_amount: number;
  total_amount: number;
  meta: TransactionMeta;
  supplier: TransactionSupplier;
  created_at: string;
  // not in API response but used for filtering, need to manually get in from meta
  doc_type?: "debit" | "credit";
  supplier_name?: string;
}

export interface TransactionMeta {
  country_code: string;
  currency_code: string;
  external_id: string;
  invoice_scenario: "debit" | "credit";
}

export interface TransactionSupplier {
  id: string;
  name: string;
}

export type ErrorTransaction = {
  job_id: string;
  external_id: string | null;
  resolved: boolean;
  country_code: string | null;
  errors: {
    code: string;
    type?: string;
    message: string;
    field?: string;
  }[];
  failed_at: string;
};

export type ErrorTransactionDetail = {
  failed_at: string;
  job_id: string;
  external_id: string;
  country_code: string;
  resolved: boolean;
  supplier: {
    id: string;
    external_id: string;
    name: string;
  };
  errors: {
    code: string;
    type?: string;
    message: string;
    field?: string;
  }[];
  related_requests: {
    job_id: string | null;
    created_at: string;
    status: string;
  }[];
  request: {
    meta: {
      invoice_scenario: string;
      service_type: string | null;
      external_id: string;
      external_event_id: string | null;
      reference_invoice_id: string | null;
      reference_invoice_external_id: string | null;
      reference_invoice_number: string | null;
      webhook: string | null;
      language_code: string;
      country_code: string;
      currency_code: string;
    };
    transaction_date: string;
    received_at: string;
    total_net_amount: number;
    total_tax_amount: number;
    total_amount: number;
    supplier: {
      id: string;
      external_id: string | null;
    };
    customer: {
      id: string;
      external_id: string | null;
    };
    operator: string | null;
    office_space: string | null;
    terminal: string | null;
    disclaimers: null;
    notes: null;
    payments: {
      payment_type: string;
      payment_type_code: null;
      amount: number;
    }[];
    items: {
      item_number: number;
      reference_item_number: null;
      item_code: null;
      item_name: string;
      quantity: number;
      unit_of_measure_code: null;
      unit_of_measure: null;
      unit_price: number;
      net_price: number;
      tax_breakdown: {
        regime: null;
        type: null;
        code: null;
        rate: number;
        amount: number;
      }[];
    }[];
  };
};

export type ErrorTransactionFilters = {
  search_phrase_type: "external-id" | "job-id";
  search_phrase: string;
  error_category: ErrorCategory | null;
  error_code: string | null;
  status: "resolved" | "unresolved" | null;
  date_from: string | null;
  date_to: string | null;
  cursor: string | null;
};

// eslint-disable-next-line no-restricted-syntax
export enum ErrorCategory {
  Validation = "validation",
  TaxAuthority = "tax_authority",
  General = "other",
}

export type TransactionsInvoiceScenario = "credit" | "full_credit" | "debit";

export interface TransactionsMeta {
  external_id?: any;
  invoice_scenario?: TransactionsInvoiceScenario;
  service_type?: string;
  business_model?: any;
  external_event_id?: any;
  reference_invoice_id?: string;
  reference_invoice_external_id?: string;
  reference_invoice_number?: string;
  language_code?: string;
  country_code?: string;
  currency_code?: string;
}

export interface TransactionsSupplier {
  id: string;
  external_id?: any;
  name: string;
  trade_name?: string | null;
}

export interface TransactionsCustomer {
  id: string;
  external_id?: any;
  name: string;
}

export interface TransactionsOperator {
  id: string;
  external_id?: any;
  name: string;
}

export interface TransactionsOfficeSpace {
  id: string;
  external_id?: any;
  name: string;
}

export interface TransactionsTerminal {
  id: string;
  external_id?: any;
  name: string;
}

export interface TransactionsPayment {
  payment_type: string;
  amount: number;
}

export interface TransactionsTaxBreakdown {
  regime?: any;
  type?: string | null;
  code?: any;
  rate: number;
  amount: number;
}

export interface TransactionsItem {
  item_number: number;
  reference_item_number?: any;
  item_code?: string;
  item_name?: string;
  quantity: number;
  unit_of_measure_code: string;
  unit_of_measure: string;
  unit_price: number;
  net_price: number;
  tax_breakdown: TransactionsTaxBreakdown[] | null;
}

export interface TransactionsDataItem {
  id: string;
  currency_code?: string;
  meta: TransactionsMeta;
  invoice_number: string;
  unique_invoice_number: string;
  transaction_date: string;
  received_at: string;
  total_net_amount: number;
  total_tax_amount: number;
  total_amount: number;
  supplier: TransactionsSupplier | null;
  customer: TransactionsCustomer | null;
  operator: TransactionsOperator | null;
  office_space: TransactionsOfficeSpace | null;
  terminal: TransactionsTerminal | null;
  disclaimers?: any;
  notes?: any;
  payments: TransactionsPayment[];
  items: TransactionsItem[];
  tax_authority_id_1?: any;
  tax_authority_id_2: string;
  created_at: string;
  updated_at?: string;
}
