import { createContext, useContext, useEffect, useState } from "react";

import { CountriesProviderProps, CountryData, GetCountriesResponse } from "./useCountries.types";

const Context = createContext<{
  isLoading: boolean;
  countries: {
    [id: string]: CountryData;
  };
}>({ isLoading: false, countries: {} });

export const CountriesProvider = ({ children, stubs }: CountriesProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [countriesResponse, setCountriesResponse] = useState<GetCountriesResponse | null>(null);

  useEffect(() => {
    if (stubs) {
      setIsLoading(true);

      // mock a loading period
      setTimeout(() => {
        setIsLoading(false);
        setCountriesResponse({
          success: true,
          data: stubs,
        });
      }, 250);

      return;
    }
  }, []);

  const countriesHashMap = Object.fromEntries(
    countriesResponse?.data?.map((country) => [country.id, country]) || []
  );

  return (
    <Context.Provider
      value={{
        isLoading: isLoading,
        countries: countriesHashMap,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCountries = () => useContext(Context);
